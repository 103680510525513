<template>
  <div>
    
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            アドミニストレータダッシュボード
            <CSwitch
              class="float-right"
              size="sm"
              shape="pill"
              color="info"
              data-on="On"
              data-off="Off"
              :checked="true"
            />
          </CCardHeader>
          <CCardBody>
            <h2>
              <!-- {{ $t("hotel.room") }} <span>{{ $t("nav") }}</span> -->
            </h2>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- TODO: -->
  </div>
</template>

<script>

export default {
  name: "DashboardList",
  data() {
    return {
      loading: false,
    };
  },
  computed: {

  },
  methods: {

  },
  beforeMount() {
    // 保持している宿ID初期化
    this.$store.commit('setUid',"");
    console.log("宿IDクリア")
    console.log(this.$store.state.uniqueId)
  },
};
</script>
